import CloseIcon from "@mui/icons-material/Close";
import { Avatar, Grid, IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AppContext } from "App";
import { ReactComponent as AppLogo } from "assets/nav-bar/app_logo.svg";
import HiddenList from "coreUI/list/hiddenList/HiddenList";
import { AppConfig } from "enums/config";
import React, { useContext } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { authSelector } from "redux/auth/selector";
import { companySelector } from "redux/company/selector";
import { valuesUrl } from "../fixedData";
import { MobileNavContext } from "../MobileNavbarContainer";
import { FixedData } from "../type";

interface Props {
  icons?: FixedData[];
}

const LeftDrawer: React.FC<Props> = ({ icons = [] }) => {
  const history = useHistory();
  const [, theme, , , , mobileMatch, , , , setTabValue] =
    useContext(AppContext);
  const [handleShowLeftDrawer, showLeftDrawer] = useContext(MobileNavContext);
  const company = useSelector(
    (state) => companySelector(state).company,
    shallowEqual
  );
  const profile = useSelector(
    (state) => authSelector(state).company,
    shallowEqual
  );

  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const handleSetPageUrl = (newValue: string) => {
    setTabValue(newValue);
    history.push(valuesUrl[newValue]);
  };
  const stringAvatar = (name: string) => {
    return {
      children: `${name[0]}`,
    };
  };

  const list = () => (
    <Box
      role="presentation"
      onClick={() => handleShowLeftDrawer(false)}
      onKeyDown={() => handleShowLeftDrawer(false)}
    >
      <HiddenList icons={icons} handleSetPageUrl={handleSetPageUrl} />
    </Box>
  );

  const checkTabScreen = useMediaQuery("(min-width:700px)");

  return (
    <SwipeableDrawer
      anchor={"left"}
      open={showLeftDrawer}
      onClose={() => handleShowLeftDrawer(false)}
      onOpen={() => handleShowLeftDrawer(true)}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      sx={{
        "& .MuiDrawer-paper": {
          width: mobileMatch ? (checkTabScreen ? "35%" : "100%") : "30%",
          bgcolor: theme.palette.primary.main,
          color: theme.palette.general.offWhite,
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={() => handleShowLeftDrawer(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.general.white,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Grid container sx={{ height: "inherit" }}>
        <Grid item xs={4} md={4} mt={"0.7em"} ml={"0.7em"}>
          {company ? (
            company?.logo_url ? (
              <img
                alt={"company logo"}
                src={company?.logo_url}
                style={{
                  width: "80%",
                  height: "auto",
                  objectFit: "contain",
                  borderRadius: AppConfig.NO_BORDER_RADIUS,
                }}
              />
            ) : (
              <Avatar
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  fontWeight: "600",
                  fontSize: "2em",
                  bgcolor: theme.palette.secondary.light,
                  color: theme.palette.primary.main,
                }}
                {...stringAvatar(company?.companyName || "")}
              />
            )
          ) : (
            <>
              {profile?.company && (
                <Avatar
                  sx={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    fontWeight: "600",
                    fontSize: "2em",
                    bgcolor: theme.palette.secondary.light,
                    color: theme.palette.primary.main,
                  }}
                  {...stringAvatar(profile?.company || "")}
                />
              )}
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          {list()}
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={3} md={4} sx={{ alignSelf: "flex-end" }}>
            <Grid
              item
              xs={12}
              sx={{
                p: 3,
                pt: "4em",
                minWidth: "5em",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.5em",
                  color: theme.palette.general.white,
                  fontWeight: 300,
                  textAlign: "center",
                }}
              >
                Powered by
              </Typography>
              <AppLogo style={{ width: "100%" }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
};

export default LeftDrawer;
