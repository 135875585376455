import EngFlag from "assets/general-icon/eng-flag.png";
import SaudiFlag from "assets/general-icon/saudi-flag.png";
import { SETTING_ROUTES } from "enums/routes";
import { TabsPermission } from "enums/tabsPermissions";
import { TFunction } from "react-i18next";
import { checkTabPermissions } from "utils/helper";

export const tabsData: (
  t: TFunction<"translation">,
  profile?: any
) => { name: string; value: number }[] = (t, profile) => [
  {
    name: t("my_account"),
    value: 1,
  },
  {
    name: t("predefine_msg"),
    value: 2,
  },
  {
    name: t("templete_msg"),
    value: 3,
  },
  {
    name: t("manage_group"),
    value: 4,
  },
  {
    name: t("manage_agent"),
    value: 5,
  },
  {
    name: t("channels"),
    value: 6,
  },
  {
    name: t("tags"),
    value: 7,
  },
  {
    name: t("company"),
    value: 8,
  },
  {
    name: t("kpi_metrics"),
    value: 9,
  },
  checkTabPermissions(profile, TabsPermission.VIEW_REQUEST_TAB) && {
    name: t("request"),
    value: 10,
  },
  {
    name: t("notification"),
    value: 11,
  },
];

export const agentTab: (
  t: TFunction<"translation">
) => { name: string; value: number }[] = (t) => [
  {
    name: t("my_account"),
    value: 1,
  },
  {
    name: t("predefine_msg"),
    value: 2,
  },
  {
    name: t("notification"),
    value: 11,
  },
];

export const superVisor: (
  t: TFunction<"translation">,
  profile?: any
) => { name: string; value: number }[] = (t, profile) => [
  {
    name: t("my_account"),
    value: 1,
  },
  {
    name: t("predefine_msg"),
    value: 2,
  },
  {
    name: t("templete_msg"),
    value: 3,
  },
  {
    name: t("channels"),
    value: 6,
  },
  {
    name: t("tags"),
    value: 7,
  },
  checkTabPermissions(profile, TabsPermission.VIEW_REQUEST_TAB) && {
    name: t("request"),
    value: 10,
  },
  {
    name: t("notification"),
    value: 11,
  },
];

// convert routes arr to object
// order of SETTING_ROUTES is important
export const valuesUrl: any = SETTING_ROUTES.reduce(
  (a, v, i) => ({ ...a, [++i]: v }),
  {}
);

export const languages: (
  t: TFunction<"translation">
) => { name: string; icon: string; value: string }[] = (t) => [
  {
    name: t("english"),
    icon: EngFlag,
    value: "en",
  },
  {
    name: t("arabic"),
    icon: SaudiFlag,
    value: "ar",
  },
];

export const genderValues: (
  t: TFunction<"translation">
) => { id: string; value: string; text: string }[] = (t) => [
  { id: "M", value: "M", text: t("male") },
  { id: "F", value: "F", text: t("female") },
];

export const unwantedHeight: number = 47;
export const paddingTabPanel = 24;

export const statusChannels: (
  t: TFunction<"translation">
) => { id: string; value: string; text: string }[] = (t) => [
  { id: "ENABLED", value: "ENABLED", text: t("enabled") },
  { id: "SUSPENDED", value: "SUSPENDED", text: t("suspended") },
];
